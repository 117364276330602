import React, { lazy, useState } from "react"

import { Header, HeaderNav } from "../Header/Header"
import LogoSVG from "../../Assets/img/logo.svg"

import { useTranslation } from "react-i18next"
import i18next from "i18next"

import { Col, Navbar, Dropdown } from "react-bootstrap"

import { Link } from "react-router-dom"

const Heading = (props) => {
  const { t } = useTranslation()
  const [lan, setLang] = useState(i18next.language.split("-")[0])

  return (
    <Header topSpace={{ md: true }} type="reverse-scroll">
      <HeaderNav
        containerClass="sm:px-0"
        theme="light"
        expand="lg"
        bg="light"
        className=" font-serif border-b border-[#0000001a] px-[40px] py-[0px] md:px-[15px] md:py-[10px] "
      >
        <Col sm={6} lg={3} xs="auto" className="me-auto ps-0 flex items-center">
          <Navbar.Brand className="inline-block p-0 m-0">
            <Link aria-label="link" to="/" className="">
              <img
                className="default-logo !max-h-14"
                width="120"
                height="36"
                loading="lazy"
                src={LogoSVG}
                data-rjs={LogoSVG}
                alt="logo"
              />
              <img
                className="alt-logo !max-h-14"
                width="111"
                height="36"
                loading="lazy"
                src={LogoSVG}
                data-rjs={LogoSVG}
                alt="logo"
              />
              <img
                className="mobile-logo"
                width="111"
                height="36"
                loading="lazy"
                src={LogoSVG}
                data-rjs={LogoSVG}
                alt="logo"
              />
            </Link>
          </Navbar.Brand>
        </Col>
        <Navbar.Toggle className="order-last md:ml-[17px] md:px-[15px] ">
          <span className="navbar-toggler-line"></span>
          <span className="navbar-toggler-line"></span>
          <span className="navbar-toggler-line"></span>
          <span className="navbar-toggler-line"></span>
        </Navbar.Toggle>
        <Navbar.Collapse className="col-auto justify-end menu-order finance-header-menu px-lg-0 col-lg-8">
          <ul className="navbar-nav font-serif uppercase bg-lightgray">
            <li className="nav-item">
              <Link
                to="/"
                offset={0}
                delay={0}
                spy={true}
                smooth={true}
                duration={800}
                className="font-serif nav-link section-link cursor-pointer"
              >
                {t("home")}
              </Link>
            </li>

            <li className="nav-item">
              <Dropdown className="  h-full text-[#343434] font-serif">
                <Dropdown.Toggle
                  className="rounded-none uppercase w-auto h-full font-medium leading-[11px] border-[0px] m-0 dropdown-toggle after:none sm-w-100 items-center flex px-[18px] py-[14px] md:px-[0px] bg-transparent text-[#343434]"
                  id="dropdown-basic"
                >
                  <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-0">
                    <img height={16} width={16} src={`/assets/img/country-flag-16X16/${t("flag")}.png`} alt="" />
                  </span>
                  {t("code")} <span className="caret"></span>{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    className="dropdown-items bg-transparent hover:bg-transparent border-0 m-0 flex items-center justify-start"
                    onClick={() => {
                      i18next.changeLanguage("en")
                      setLang("en")
                    }}
                  >
                    <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-[5px]">
                      <img height={16} width={16} src="/assets/img/country-flag-16X16/usa.png" alt="" />
                    </span>
                    English
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="dropdown-items bg-transparent hover:bg-transparent border-0 m-0 flex items-center justify-start"
                    onClick={() => {
                      i18next.changeLanguage("it")
                      setLang("it")
                    }}
                  >
                    <span className="icon-country w-[16px] h-[16px] inline-block my-0 mr-[10px] ml-[5px]">
                      <img height={16} width={16} src="/assets/img/country-flag-16X16/Italy.png" alt="" />
                    </span>
                    Italiano
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </Navbar.Collapse>
      </HeaderNav>
    </Header>
  )
}

export default Heading
